














































import Vue from "vue";
import { mapGetters } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { CollectionV2, ImageUpload } from "@/store/types";

export default Vue.extend({
  name: "CollForm",

  props: {
    collection: { type: Object, default: null }
  },

  data() {
    return {
      select: [
        { text: "Sim", value: true },
        { text: "Não", value: false }
      ],
      payload: {
        _id: "",
        name: "",
        picture: "",
        rank: 1,
        active: true,
        archived: false,
        created_at: new Date(),
        updated_at: new Date(),
      } as CollectionV2
    };
  },

  computed: {
    ...mapGetters({
      apps: "mobileAppsModule/getApps",
    })
  },

  mounted() {
    if (this.collection) {
      Object.assign(this.payload, this.collection)
    }
  },

  methods: {
    save(): void {
      if (!this.validateForm()) return;

      if (this.collection) {
        this.payload.updated_at = new Date()
        this.$store.dispatch("collectionsV2Module/update", this.payload);
      } else {
        this.$store.dispatch("collectionsV2Module/create", this.payload);
      }

      this.$emit("close");
    },

    validateForm(): Boolean {
      // if (!this.payload.sku) {
      //   this.showError("Insira o SKU da oferta!");
      //   return false;
      // }
      return true;
    },

    showError(message: string): void {
      this.$store.dispatch("app/showCustomError", message);
    },

    getFile(file: File) {
      if (!file.type.includes("image")) {
        return this.$store.dispatch("app/showCustomError", "Arquivo não Suportado!");
      }

      const extension = file.name.split(".")[1];

      const reader = new FileReader();
      reader.onload = async ({ target }) => {
        const image: ImageUpload = {
          folder: "/collections",
          filename: `${uuidv4()}.${extension}`,
          file: new Uint8Array(target?.result as ArrayBuffer),
          metadata: {
            contentType: file.type,
            size: file.size,
          }
        }

        this.$store.dispatch("uploader/upload", image)
            .then(url => {
              this.payload.picture = url
            })
      };
      reader.readAsArrayBuffer(file);
    }
  }
});
