

















































import {CollectionV2, MobileApp } from "@/store/types";
import Vue from "vue";
import { mapGetters } from "vuex";
import ConfirmDialog from "../../../components/ConfirmDialog.vue";

export default Vue.extend({
  name: "CollTable",

  components: {
    ConfirmDialog,
  },

  props: {
    items: { type: Array, default: null },
    loading: { type: Boolean, default: false },
    total: { type: Number, default: 0 }
  },

  data: () => ({
    showDeleteDialog: false,
    collToDelete: null as unknown | CollectionV2,
    options: {},
    page: 1,
    pageSize: 10,
    headers: [
      {
        text: "Rank",
        align: "start",
        value: "rank",
        width: "100"
      },
      {
        text: "Imagem",
        value: "picture",
        sortable: false,
        width: "100"
      },
      {
        text: "Título",
        align: "start",
        value: "name",
      },
      {
        text: "Apps",
        value: "apps",
        align: "center",
        sortable: false
      },
      {
        text: "Status",
        value: "info",
        align: "center",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        width: "110",
        align: "center",
        sortable: false
      }
    ] as Array<Object>
  }),

  computed: {
    ...mapGetters({
      apps: "mobileAppsModule/getApps",
    })
  },

  watch: {
    options: {
      handler (options) {
        this.fetch(options.page, options.itemsPerPage);
      },
      deep: true,
    },
  },

  methods: {
    fetch(page: number, pageSize: number): void {
      if (page === this.page && pageSize === this.pageSize) return;
      this.page = page;
      this.pageSize = pageSize;

      // FIXME o backend começa a paginação em 0, resolver isso depois
      this.$emit("fetch", { page: page - 1, pageSize })
    },

    showDelDialog(item: CollectionV2): void {
      this.showDeleteDialog = true
      this.collToDelete = item;
    },

    onEdit(item: CollectionV2): void {
      this.$emit("onEdit", item)
    },

    onDelete(): void {
      this.$emit("onDelete", this.collToDelete)
      this.showDeleteDialog = false
    },

    buildAppNames(ids: string[]): string {
      return this.apps
          .filter((app: MobileApp) => ids.includes(app._id))
          .map((app: MobileApp) => app.app_name)
          .join(", ")
    }
  }
})
