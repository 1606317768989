
































import Vue from "vue";
import { mapGetters } from "vuex";
import FullScreenDialog from "@/components/FullScreenDialog.vue";
import CollForm from "./components/CollForm.vue";
import CollTable from "./components/CollTable.vue";
import { CollectionV2 } from "@/store/types";

export default Vue.extend({
  name: "CollectionsV2",

  components: {
    FullScreenDialog,
    CollForm,
    CollTable
  },

  data: () => ({
    fullScreenDialog: false,
    collection: null as unknown | CollectionV2,
  }),

  computed: {
    ...mapGetters({
      collections: "collectionsV2Module/getCollections",
      total: "collectionsV2Module/getTotal",
      loading: "collectionsV2Module/getLoading"
    })
  },

  created() {
    this.$store.dispatch("mobileAppsModule/fetch")
    this.$store.dispatch("collectionsV2Module/fetch");
  },

  methods: {
    editCol(collection: CollectionV2): void {
      this.collection = collection;
      this.openFullScreenDialog();
    },

    deleteCol(collection: CollectionV2): void {
      this.$store.dispatch("collectionsV2Module/archive", { _id: collection._id });
    },

    fetch(payload: { page: number, pageSize: number }): void {
      this.$store.dispatch("collectionsV2Module/fetch", payload);
    },

    openFullScreenDialog(): void {
      this.fullScreenDialog = true;
    },

    closeFullScreenDialog(): void {
      this.fullScreenDialog = false;
      this.collection = null;
    },
  }
})
